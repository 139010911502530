


  import dep0 from "@ember/test-waiters/-embroider-implicit-modules.js";
  import dep1 from "@embroider/router/-embroider-implicit-modules.js";
  import dep2 from "@glimmer/component/-embroider-implicit-modules.js";
  import dep3 from "ember-container-query/-embroider-implicit-modules.js";
  import dep4 from "ember-deep-tracked/-embroider-implicit-modules.js";
  import dep5 from "ember-element-helper/-embroider-implicit-modules.js";
  import dep6 from "ember-focus-trap/-embroider-implicit-modules.js";
  import dep7 from "ember-modifier/-embroider-implicit-modules.js";
  import dep8 from "ember-primitives/-embroider-implicit-modules.js";
  import dep9 from "ember-repl/-embroider-implicit-modules.js";
  import dep10 from "ember-resources/-embroider-implicit-modules.js";
  import dep11 from "ember-statechart-component/-embroider-implicit-modules.js";
  import dep12 from "kolay/-embroider-implicit-modules.js";
  import dep13 from "limber-ui/-embroider-implicit-modules.js";
  import dep14 from "reactiveweb/-embroider-implicit-modules.js";
  import dep15 from "tracked-built-ins/-embroider-implicit-modules.js";
  import dep16 from "tracked-toolbox/-embroider-implicit-modules.js";
  import dep17 from "@ember/test-helpers/-embroider-implicit-modules.js";
  import dep18 from "@fortawesome/ember-fontawesome/-embroider-implicit-modules.js";
  import dep19 from "ember-async-data/-embroider-implicit-modules.js";
  import dep20 from "ember-cli-babel/-embroider-implicit-modules.js";
  import dep21 from "ember-modifier/-embroider-implicit-modules.js";
  import dep22 from "ember-page-title/-embroider-implicit-modules.js";
  import dep23 from "ember-qunit/-embroider-implicit-modules.js";
  import dep24 from "ember-route-template/-embroider-implicit-modules.js";
  import dep25 from "ember-source/-embroider-implicit-modules.js";
  import dep26 from "@ember/optional-features/-embroider-implicit-modules.js";
  import dep27 from "@embroider/macros/-embroider-implicit-modules.js";
  import dep28 from "@embroider/util/-embroider-implicit-modules.js";
  import dep29 from "ember-auto-import/-embroider-implicit-modules.js";
  import dep30 from "broccoli-asset-rev/-embroider-implicit-modules.js";
  import dep31 from "ember-cli-browserstack/-embroider-implicit-modules.js";
  import dep32 from "ember-resize-observer-service/-embroider-implicit-modules.js";
  import dep33 from "ember-on-resize-modifier/-embroider-implicit-modules.js";
  import dep34 from "loader.js/-embroider-implicit-modules.js";
  import dep35 from "@glimmer/tracking/-embroider-implicit-modules.js";


export default Object.assign({},
    dep0,
    dep1,
    dep2,
    dep3,
    dep4,
    dep5,
    dep6,
    dep7,
    dep8,
    dep9,
    dep10,
    dep11,
    dep12,
    dep13,
    dep14,
    dep15,
    dep16,
    dep17,
    dep18,
    dep19,
    dep20,
    dep21,
    dep22,
    dep23,
    dep24,
    dep25,
    dep26,
    dep27,
    dep28,
    dep29,
    dep30,
    dep31,
    dep32,
    dep33,
    dep34,
    dep35,
  {
  }
);
