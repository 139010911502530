


  import dep0 from "ember-resources/-embroider-implicit-modules.js";
  import dep1 from "@embroider/macros/-embroider-implicit-modules.js";


export default Object.assign({},
    dep0,
    dep1,
  {
  }
);
