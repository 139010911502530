


  import dep0 from "@fortawesome/ember-fontawesome/-embroider-implicit-modules.js";
  import dep1 from "reactiveweb/-embroider-implicit-modules.js";
  import dep2 from "@embroider/macros/-embroider-implicit-modules.js";


export default Object.assign({},
    dep0,
    dep1,
    dep2,
  {
  }
);
