
import { macroCondition, getGlobalConfig } from '@embroider/macros';


import implicitModules from "./-embroider-implicit-modules.js";


import * as amdModule0 from "./app.ts"
import * as amdModule1 from "./config/environment.js"
import * as amdModule2 from "./icons.ts"
import * as amdModule3 from "./registry.ts"
import * as amdModule4 from "./router.ts"
import * as amdModule5 from "./services/editor.ts"
import * as amdModule6 from "./snippets.ts"
import * as amdModule7 from "./utils/editor-text.ts"
import * as amdModule8 from "./utils/messaging.ts"
import * as amdModule9 from "./services/resize-observer.js"
import * as amdModule10 from "./services/-ensure-registered.js"
import * as amdModule11 from "./services/page-title.js"
import * as amdModule12 from "./services/kolay/api-docs.js"
import * as amdModule13 from "./services/kolay/compiler.js"
import * as amdModule14 from "./services/kolay/docs.js"
import * as amdModule15 from "./services/kolay/selected.js"
import * as amdModule16 from "./templates/application.gts"
import * as amdModule17 from "./controllers/application.ts"
import * as amdModule18 from "./routes/application.ts"
import * as amdModule19 from "./templates/edit.gts"
import * as amdModule20 from "./routes/edit.ts"
import * as amdModule21 from "./routes/error.ts"
import * as amdModule22 from "./routes/index.ts"
import * as amdModule23 from "./templates/output.gts"

let exportFastbootModules = {};





export default Object.assign(
  {},
  implicitModules,
  {
"limber/app": amdModule0,
"limber/config/environment": amdModule1,
"limber/icons": amdModule2,
"limber/registry": amdModule3,
"limber/router": amdModule4,
"limber/services/editor": amdModule5,
"limber/snippets": amdModule6,
"limber/utils/editor-text": amdModule7,
"limber/utils/messaging": amdModule8,
"limber/services/resize-observer": amdModule9,
"limber/services/-ensure-registered": amdModule10,
"limber/services/page-title": amdModule11,
"limber/services/kolay/api-docs": amdModule12,
"limber/services/kolay/compiler": amdModule13,
"limber/services/kolay/docs": amdModule14,
"limber/services/kolay/selected": amdModule15,
"limber/templates/application": amdModule16,
"limber/controllers/application": amdModule17,
"limber/routes/application": amdModule18,
"limber/templates/edit": amdModule19,
"limber/routes/edit": amdModule20,
"limber/routes/error": amdModule21,
"limber/routes/index": amdModule22,
"limber/templates/output": amdModule23,
  },
  exportFastbootModules
);
